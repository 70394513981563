import React, { ReactElement } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';

// import Main from './views/Main/Main';
import ComingSoon from './views/ComingSoon/ComingSoon';

// import Thanks from './views/Thanks/Thanks';
// import Rules from './views/Rules/Rules';
// import Winners from './views/Winners/Winners';
import usePageTracking from './components/page-tracking/usePageTracking';

const App: React.FC = (): ReactElement => {
  usePageTracking();
  return (
    <div className="App">
      {/* <Main /> */}
      <Routes>
        <Route path="/" element={<ComingSoon />} />

        {/*<Route path="/" element={<Main />} />
        <Route path="/thanks" element={<Thanks />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/winners" element={<Winners />} />*/}
      </Routes>
    </div>
  );
};

export default App;
